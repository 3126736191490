<template>
  <div class="shop-create">
    <el-page-header @back="$router.back()" content="创建门店">
    </el-page-header>
    <div class="shop-create-container">
      <info :shop="shop"
            @commit="handleCommit"
            @cancel="handleCancel"></info>
    </div>
  </div>

</template>

<script>
  import Info from './components/info'

  export default {
    name: '',
    components: {
      Info
    },
    mounted: function () {

    },
    data: function () {
      return {
        shop: {
          name: '',
          description: '',
          contactName: '',
          contactPhone: '',
          dutyName: '',
          dutyPhone: '',
          week: 127,
          startAt: 1800,
          endAt: 52200,
          province: '',
          city: '',
          cityCode: '',
          address: '',
          lat: '',
          lng: '',
          slides: []
        },
      }
    },
    methods: {
      handleCommit(info) {
        this.$api('mkt.shop.create',{},info).then(() => {
          this.$message.success('创建成功')
          this.$router.back()
        })
      },

      handleCancel() {
        this.$router.back()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .shop-create {
    @include container();

    &-container {
      width: 600px;
      margin-left: 120px;
      margin-top: 36px;
    }
  }
</style>
